/* eslint-disable max-len */
export interface ISocial{
  name: 'LinkedIn' |
  'Behance' |
  'Github' |
  'Instagram' |
  'Facebook';
  url: string;
}

export interface ICertification{
  name: string;
  issuedBy: string;
  verificationURL: string;
}

export interface IProject{
  imageUrl?: string;
  name: string;
  companyName: string;
  url: string;
  description: string[];
  tags: string[];
}

export interface IExperience{
  shortName: string;
  companyName: string;
  endDate: string;
  position: string;
  startDate: string;
  summary: string;
  website: string;
}

export interface ISkill{
  languages: string[];
  frameworks: string[];
  tools: string[];
  design: string[];
  devtools: string[];
}

export interface IStudent{
  avatar: string;
  name: string;
  quote: string;
}

export interface IProfile{
  name: string;
  intro: string;
  skills?: ISkill;
  phone: string;
  email: string;
  socials: ISocial[];
  bio: string[];
  students?: IStudent[];
  experiences?: IExperience[];
  certifications?: ICertification[];
  projects?: IProject[];
  goodByeText: string;
}

const profile : IProfile = {
  name: 'Liezheel Mynha Alejandro',
  intro: 'Management Trainee and Software Developer',
  // students: [
  //   {
  //     name: 'Awais Zafar',
  //     avatar: 'https://media-exp1.licdn.com/dms/image/C5103AQHNewoR5FzCaQ/profile-displayphoto-shrink_400_400/0/1587200960601?e=1623888000&v=beta&t=mquh0pdkolkg1VWbpkIKU2BlskgnfJSyNhTxyowWVU8',
  //     quote: 'BSCS Lahore Garrison University',
  //   },
  //   {
  //     name: 'Umair Zafar',
  //     avatar: 'https://media-exp1.licdn.com/dms/image/C4D03AQEnZr7IWnstyQ/profile-displayphoto-shrink_400_400/0/1615956840787?e=1623888000&v=beta&t=HtxlJheHPxhmfW9J0M7gxlzDcG0yAtsuKcmPWBjxNCY',
  //     quote: 'Mechanical Engineer turned Front-End Engineer',
  //   },
  //   {
  //     name: 'Muhammad Naeem Shahzad',
  //     avatar: 'https://media-exp1.licdn.com/dms/image/C4D03AQEpvSpnRoE2fQ/profile-displayphoto-shrink_400_400/0/1615923251107?e=1623888000&v=beta&t=zMxgT_UcKU8T_S-bcV1pgwEtuCybrwQGmbssd5NZY-A',
  //     quote: 'Front-End Developer at Uncle Fixer',
  //   },
  //   {
  //     name: 'Ailyah Christopher',
  //     avatar: 'https://media-exp1.licdn.com/dms/image/C4D03AQFtX6s0y9aMjw/profile-displayphoto-shrink_400_400/0/1615563401157?e=1625097600&v=beta&t=lBw3lei20-m5lL147KACGlYzXbQYAds0uoc6bdMxDKg',
  //     quote: 'Python Enthusiast',
  //   },
  //   {
  //     name: 'Ahmad Muzzafar',
  //     avatar: '',
  //     quote: 'Learning to crawl, walk, run and fly',
  //   },
  //   {
  //     name: 'Muhammad Shahzeb',
  //     avatar: '',
  //     quote: 'Learning to crawl, walk, run and fly',
  //   },
  //   {
  //     name: 'Farhan Ahmad',
  //     avatar: '',
  //     quote: 'Learning to crawl, walk, run and fly',
  //   },
  // ],
  phone: '',
  socials: [
    {
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/liezheelmynha',
    },
    {
      name: 'Github',
      url: 'https://github.com/mynhaalejandro',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/myna.alejandro',
    },
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/mynha.alejandro',
    },
  ],
  email: 'liezheel.alejandro@gmail.com',
  bio: ['I\'m a Manila-based software developer who specializes in building exceptional digital experiences for private and public agencies. Currently, I\'m a Senior programmer at moodLearning focused on building e-learning and technology services.'],
  skills: {
    languages: ['PHP', 'Javascript (ES6)', 'TypeScript', 'HTML', 'CSS', 'SQL', 'Python'],
    frameworks: ['CodeIgniter', 'Laravel'],
    tools: ['Git', 'Postman'],
    design: ['Canva'],
    devtools: ['VSCode', 'Visual Studio'],
  },
  experiences: [
    {
      shortName: 'moodLearning',
      companyName: 'moodLearning, Inc.',
      endDate: 'Present',
      position: 'Management Trainee',
      startDate: 'March 2022',
      summary: 'My responsibilities include the following:\n1. Engaging with clients to collect and analyze requirements. \n2. Actively involved in the Design and Development of Application Infrastructure. \n3. Working closely with the Development Team to keep projects on track.',
      website: 'https://www.moodlearning.com/',
    },
    {
      shortName: 'moodLearning',
      companyName: 'moodLearning, Inc.',
      endDate: 'Present',
      position: 'Senior Programmer',
      startDate: 'October 2021',
      summary: 'As a Senior Programmer at moodLearning, I specializes in the field of Software development and customization. Working on systems like accounting, attendance and e-learning, and dealt with platforms like web and mobile. Also, manages Ubuntu-based servers and MySQL and PostgreSQL Databases.',
      website: 'https://www.moodlearning.com/',
    },
    {
      shortName: 'moodLearning',
      companyName: 'moodLearning, Inc.',
      endDate: 'October 2021',
      position: 'Software Developer',
      startDate: 'July 2019',
      summary: 'While working as a Software Developer, my responsibilities included migrating the Quakey app, an android app that monitors earthquakes in the Philippines by parsing PHILVOCS and USGS data and visualizing them, into a cross-platform mobile app using Ionic Angular. Also, dealing with a client in building their competency-based e-learning platform.',
      website: 'https://www.moodlearning.com/',
    },
    // {
    //   shortName: 'PASC',
    //   companyName: 'PUCIT - ACM Student Chapter',
    //   endDate: 'December 2017',
    //   position: 'Lead Developer',
    //   startDate: 'December 2016',
    //   summary: 'Acting as a Lead Developer, my job was to supervise the creation and maintenance of PASC\'s website built on Wordpress CMS.',
    //   website: 'https://www.linkedin.com/company/pucit-acm-student-chapter/',
    // },
    // {
    //   shortName: 'PASC',
    //   companyName: 'PUCIT - ACM Student Chapter',
    //   endDate: 'December 2016',
    //   position: 'Graphic Designer',
    //   startDate: 'December 2015',
    //   summary: 'As a Graphic Designer, my job at PASC was to design eye-catching designs that represented the chapter and its ideology.',
    //   website: 'https://www.linkedin.com/company/pucit-acm-student-chapter/',
    // },
    // {
    //   shortName: 'PUCIT',
    //   companyName: 'Punjab University College of Information Technology, Lahore',
    //   endDate: '',
    //   position: 'Teacher Assistant',
    //   startDate: '2013',
    //   summary: 'In the apprenticeship of Sir Imran Khalil, I was given the opportunity to assist the bright minds of the future in their Programming Fundamentals Course. This opportunity served me as a benchmark to learn crowd management and presentational skills.',
    //   website: 'https://www.linkedin.com/company/punjab-university-college-of-information-technology-lahore/',
    // },
    // {
    //   shortName: 'Plan9',
    //   companyName: 'Plan9 - PITB\'s Tech Incubator',
    //   endDate: 'October 2015',
    //   position: 'Graphic Designer',
    //   startDate: 'August 2015',
    //   summary: 'At Trequant, my job was to create the export interface for their Bio-tech prototype - which consisted of a wrist-watch. Also, my job was to make designs for their Social Media Marketing campaign and for their website.',
    //   website: 'https://www.linkedin.com/company/plan9---pitbs-tech-incubator/',
    // },
  ],
  // certifications: [{
  //   name: 'Front End Libraries',
  //   issuedBy: 'freeCodeCamp',
  //   verificationURL: 'https://www.freecodecamp.org/certification/fahadachaudhry/front-end-libraries',
  // },
  // {
  //   name: 'JavaScript Algorithms and Data Structures',
  //   issuedBy: 'freeCodeCamp',
  //   verificationURL: 'https://www.freecodecamp.org/certification/fahadachaudhry/javascript-algorithms-and-data-structures',
  // },
  // {
  //   name: 'Advanced ReactJS',
  //   issuedBy: 'CodeCademy',
  //   verificationURL: 'https://www.codecademy.com/profiles/fahadachaudhry',
  // },
  // {
  //   name: 'Introduction to ReactJS',
  //   issuedBy: 'CodeCademy',
  //   verificationURL: 'https://www.codecademy.com/profiles/fahadachaudhry',
  // },
  // ],
  projects: [
    {
      name: 'Garlic Varieties Classifier',
      imageUrl: 'https://cdn.lmalejandro.com/projects/garlic_varieties_classifier.jpeg',
      companyName: '',
      url: 'https://thesis.lmalejandro.com/',
      description: [
        'Classification of registered garlic varieties on the basis of their visual markers namely color and shape based features using digital image processing techniques in OpenCV.',
      ],
      tags: ['Python', 'MachineLearning', 'OpenCV'],
    },
    {
      name: 'rubberfabricationph.com',
      imageUrl: 'https://cdn.lmalejandro.com/projects/rubber_fabrication_ph.jpeg',
      companyName: 'Winx2020 Rubber Seals Trading',
      url: 'https://rubberfabricationph.com/',
      description: [
        'Rubber supplier and manufacturer that specializes in providing low-cost customized rubber gaskets and other rubber products. They strives to develop high-quality products that meet the needs of its customers.',
      ],
      tags: ['Wordpress', 'CDN', 'SearchEngineOptimization', 'ResponsiveDesign'],
    },
    {
      name: 'Frug.al',
      imageUrl: 'https://cdn.lmalejandro.com/projects/frug_al.jpeg',
      companyName: 'Fupping Media',
      url: 'https://frug.al/',
      description: ['This web application uses Machine Learning to analyze millions of products, comparing cost and quality to provide you with curated lists of the greatest value and cheapest products on the UK market.'],
      tags: ['MachineLearning', 'Laravel'],
    },
    // {
    //   name: 'Valary | VSCode Theme',
    //   imageUrl: 'https://images.unsplash.com/photo-1461749280684-dccba630e2f6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
    //   companyName: '',
    //   url: 'https://marketplace.visualstudio.com/items?itemName=fahadachaudhry.valary',
    //   description: ['Valary is an immersive Dark Theme for VSCode. Currently available on Microsoft VSCode Marketplace.'],
    //   tags: ['Yeoman', 'CSS', 'JSON'],
    // },
    // {
    //   name: 'Innovation Projects',
    //   imageUrl: 'https://images.unsplash.com/photo-1523240795612-9a054b0db644?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
    //   companyName: 'CureMD Research and Development',
    //   url: '',
    //   description: ['I have proposed and successfully developed the following projects as personal innovative suggestions at CureMD Research & Development:',
    //     '1. Revamping the referrals module by User export interface reusability/flexibility and limiting the content flow on screen for the user to avoid overcrowdedness of information.',
    //     '2. Users prompt reduction to enhance interruption-free user experience in the Superbill module.',
    //     '3. Development of a dashboard to resolve client requests(L3s) at a decreased SLA.',
    //   ],
    //   tags: ['.NET', 'JS', 'CSS', 'HTML'],
    // },
    // {
    //   name: 'Billing Rule Engine - Expert System',
    //   imageUrl: 'https://images.unsplash.com/photo-1578496479763-c21c718af028?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80',
    //   companyName: 'CureMD Research and Development',
    //   url: '',
    //   description: ['In this project, we enabled the doctors to identify and rectify insurance-claim rejections at the appointment stage which is far earlier than the billing stage, using a C# based web service. This step reduced the insurance-claims rejections drastically and improved the overall efficiency of the billing process.'],
    //   tags: ['.NET', 'JS', 'CSS', 'HTML'],
    // },
  ],
  goodByeText: 'My inbox is always open for any opportunities. If you have a question or just want to say hi, I\'ll try my best to get back to you!',
};

const getProfileData = () => profile;

export default getProfileData;
